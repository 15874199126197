/* eslint-disable global-require */
import { ssrMode } from "@temp/constants";

export const PROVIDERS = {
  DUMMY: {
    label: "Dummy",
  },
  STRIPE: {
    label: "Stripe",
  },
};

export const META_DEFAULTS = {
  custom: [],
  description:
    "Dress hire grows your wardrobe with over 20,000 new, chic designer options. We help women get the perfect dress for every event on their calendar.",
  image: "",
  title: "Dress Hire | Australia's Largest Dress Hire Selection",
  type: "website",
  url: !ssrMode ? window.location.origin : "",
};
