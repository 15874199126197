import * as React from "react";

import { DynamicReactIcon } from "@components/atoms/DynamicReactIcon";

interface SocialMedia {
  ariaLabel: string;
  link: string;
  icon: string;
  iconFamily: string;
}

interface IconProps extends React.HTMLProps<HTMLAnchorElement> {
  social: SocialMedia;
}

const SocialMediaIcon: React.FC<IconProps> = ({ social, target }) => (
  <a
    href={social.link}
    target={target || "_blank"}
    aria-label={social.ariaLabel}
  >
    <DynamicReactIcon
      icon={social.icon}
      iconFamily={social.iconFamily}
      size={20}
    />
  </a>
);

export default SocialMediaIcon;
