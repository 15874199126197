import dynamic from "next/dynamic";
import type { IconBaseProps } from "react-icons";
import { BiCircle } from "react-icons/bi";
import { FaRegCircle } from "react-icons/fa6";
import { FiCircle } from "react-icons/fi";

type DynamicReactIconProps = {
  iconFamily?: string | null | undefined;
  icon?: string | null | undefined;
  size?: string | number | undefined;
};

type IconsMapping = {
  [key: string]: React.ComponentType<IconBaseProps>;
};

export const DynamicReactIcon = ({
  iconFamily = "fa6",
  icon,
  size,
}: DynamicReactIconProps) => {
  const ic = icon || "FaRegCircle";

  const Icons: IconsMapping = {
    // Add other icon families as needed
    fa6: dynamic(
      () =>
        import("react-icons/fa6")
          .then((mod) => mod[ic])
          .then((e) => (e === undefined ? FaRegCircle : e)) as Promise<
          React.ComponentType<IconBaseProps>
        >
    ),
    fi: dynamic(
      () =>
        import("react-icons/fi")
          .then((mod) => mod[ic])
          .then((e) => (e === undefined ? FiCircle : e)) as Promise<
          React.ComponentType<IconBaseProps>
        >
    ),
    bi: dynamic(
      () =>
        import("react-icons/bi")
          .then((mod) => mod[ic])
          .then((e) => (e === undefined ? BiCircle : e)) as Promise<
          React.ComponentType<IconBaseProps>
        >
    ),
  };

  const Icon = iconFamily && icon ? Icons[iconFamily] : null;

  return <>{Icon && <Icon size={size} />}</>;
};
