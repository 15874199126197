import { FormattedMessage, useIntl } from "@react-intl";
import { OrdersByUser_me_orders_edges_node_reservations } from "@the-volte/svc-core-sdk/lib/queries/gqlTypes/OrdersByUser";
import * as React from "react";
import Media from "react-media";

import { commonMessages } from "@temp/intl";
import { isPurchase } from "@utils/misc";

import { smallScreen } from "../../../globalStyles/variables";
import CostRow from "./CostRow";
import ProductRow, { EditableProductRowProps, ILine } from "./ProductRow";
import S from "./styles";

interface TableProps extends EditableProductRowProps {
  lines: ILine[];
  subtotal: React.ReactNode;
  borrowerFee?: React.ReactNode;
  deliveryCost?: React.ReactNode;
  totalCost?: React.ReactNode;
  discount?: React.ReactNode;
  discountName?: string;
  rentalPeriod?: number | null;
  reservations:
    | (OrdersByUser_me_orders_edges_node_reservations | null)[]
    | null;
  displayNewHeaders?: boolean;
}

const Table: React.FC<TableProps> = ({
  subtotal,
  deliveryCost,
  borrowerFee,
  totalCost,
  discount,
  discountName,
  lines,
  rentalPeriod,
  reservations,
  displayNewHeaders = false,
  ...rowProps
}) => {
  const intl = useIntl();
  return (
    <Media query={{ minWidth: smallScreen }}>
      {(mediumScreen) => (
        <S.CartTable>
          <thead>
            <tr>
              <th>
                {displayNewHeaders ? (
                  "Item"
                ) : (
                  <FormattedMessage {...commonMessages.products} />
                )}
              </th>
              {mediumScreen && (
                <th>
                  {displayNewHeaders ? (
                    "Per Unit"
                  ) : (
                    <FormattedMessage {...commonMessages.price} />
                  )}
                </th>
              )}
              <th>
                <FormattedMessage {...commonMessages.variant} />
              </th>
              <th className="quantity-header">
                <FormattedMessage {...commonMessages.qty} />
              </th>
              <th colSpan={1} style={{ textAlign: "right" }}>
                {displayNewHeaders ? (
                  "Total (incl GST)"
                ) : mediumScreen ? (
                  <FormattedMessage {...commonMessages.totalPrice} />
                ) : (
                  <FormattedMessage {...commonMessages.price} />
                )}
              </th>
            </tr>
          </thead>
          <tbody>
            {lines.map((line) => (
              <ProductRow
                reservation={reservations?.[0]}
                key={line.id}
                line={line}
                mediumScreen={mediumScreen}
                {...rowProps}
              />
            ))}
          </tbody>
          <tfoot>
            <CostRow
              mediumScreen={mediumScreen}
              heading={intl.formatMessage(commonMessages.variant)}
              cost={subtotal}
            />
            {discount && (
              <CostRow
                mediumScreen={mediumScreen}
                heading={intl.formatMessage(
                  { defaultMessage: "Discount: {discountName}" },
                  { discountName }
                )}
                cost={discount}
              />
            )}
            {deliveryCost && (
              <CostRow
                mediumScreen={mediumScreen}
                heading={intl.formatMessage({
                  defaultMessage: "Delivery Cost",
                })}
                cost={deliveryCost}
              />
            )}
            {borrowerFee && (
              <CostRow
                mediumScreen={mediumScreen}
                heading={intl.formatMessage(
                  isPurchase(rentalPeriod)
                    ? commonMessages.purchaserFee
                    : commonMessages.borrowerfee
                )}
                cost={borrowerFee}
              />
            )}
            {totalCost && (
              <CostRow
                mediumScreen={mediumScreen}
                heading={intl.formatMessage({ defaultMessage: "Total Cost" })}
                cost={totalCost}
              />
            )}
          </tfoot>
        </S.CartTable>
      )}
    </Media>
  );
};

export default Table;
