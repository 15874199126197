import { Box, ScaleFade, Spinner } from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import { ReactSVG } from "react-svg";

import { klaviyoPublicKey, klaviyoSubscribeListId } from "@app/constants";
import { commonMessages } from "@app/intl";

import arrowImg from "images/arrow-right.svg";

const handleAddToKlavyoList = async (
  email: string,
  handleSuccessCallback: () => void,
  handleErrorCallback: (errorMessage: string) => void
) => {
  try {
    const url = `https://a.klaviyo.com/client/subscriptions/?company_id=${klaviyoPublicKey}`;
    const options = {
      method: "POST",
      headers: { revision: "2023-06-15", "content-type": "application/json" },
      body: JSON.stringify({
        data: {
          type: "subscription",
          attributes: {
            list_id: klaviyoSubscribeListId,
            custom_source: "Homepage footer signup form",
            email,
          },
        },
      }),
    };
    const response = await fetch(url, options);
    if (response.ok) {
      handleSuccessCallback();
    } else {
      const errorData = await response.json();
      const errorMessage = errorData.errors?.[0]?.detail || "An error occurred";
      handleErrorCallback(errorMessage);
    }
  } catch (e) {
    handleErrorCallback(
      "An error occurred while subscribing. Please try again."
    );
  }
};

const KlaviyoSubscriber = () => {
  const [email, setEmail] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");

  const inputRef = useRef<HTMLInputElement>(null);

  const handleSubscribe = async (e: React.FormEvent) => {
    e.preventDefault();

    const handleSuccessCallback = () => {
      setSuccessMessage(commonMessages.thankYouSubscribe.defaultMessage);
      setEmail("");
    };

    const handleErrorCallback = (errorMessage: string) => {
      setError(errorMessage);
    };

    setLoading(true);
    setError("");

    if (!inputRef?.current?.checkValidity()) {
      setError("Invalid email address");
      setLoading(false);
      return;
    }

    if (!email) {
      setError("Email is required");
      setLoading(false);
      return;
    }

    try {
      await handleAddToKlavyoList(
        email,
        handleSuccessCallback,
        handleErrorCallback
      );
    } catch (err) {
      setError("An error occurred while subscribing. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSubscribe(e);
    }
  };

  return (
    <Box bgColor="tertiary" w="full">
      <Box mx="auto">
        <Box fontSize="1.4rem" lineHeight="1.5rem">
          STAY IN THE KNOW ON THE LATEST STYLES
        </Box>
        <Box display="flex" mx="auto" mt="1rem">
          {!successMessage ? (
            <Box
              as="form"
              borderBottom="1px dashed white"
              display="flex"
              justifyContent="space-between"
              py="0.5rem"
              fontSize="1.2rem"
              mx={loading ? "auto" : "none"}
              onSubmit={handleSubscribe}
            >
              {loading ? (
                <Spinner />
              ) : (
                <>
                  <Box
                    ref={inputRef}
                    as="input"
                    type="email"
                    placeholder={commonMessages.enterEmail.defaultMessage}
                    bgColor="tertiary"
                    color="white"
                    outline="none"
                    _placeholder={{ color: "white" }}
                    onKeyUp={handleKeyUp}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setEmail(e.target.value)
                    }
                    value={email}
                    required
                  />
                  <Box as="button" pl="1rem" display="inline" type="submit">
                    <ReactSVG src={arrowImg.src} />
                  </Box>
                </>
              )}
            </Box>
          ) : (
            <ScaleFade initialScale={0.6} in>
              {successMessage}
            </ScaleFade>
          )}
        </Box>
        <Box textAlign="left" my="0.5rem" color="red">
          {error}
        </Box>
      </Box>
    </Box>
  );
};

export default KlaviyoSubscriber;
